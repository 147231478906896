import React from "react";
import "./FooterStyles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import user from "../../lib/user";

function Footer() {
  const userData = user[0];

  return (
    <div className="footer">
      <div className="links">
        <span>
          <a href={userData.github}>
            <FontAwesomeIcon icon={faGithub} />
          </a>
          <a href={userData.linkedin}>
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a href={userData.youtube}>
            <FontAwesomeIcon icon={faYoutube} />
          </a>
        </span>
        <br />
        <br />
      </div>
      <p>
        &copy; 2024 James McQueen
        <br />
        All Rights Reserved
        <br />
        <br />
        Website Created by <a>James McQueen</a>
      </p>
    </div>
  );
}

export default Footer;
