import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faFileAlt } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./ProjectCardStyle.css";

function ProjectCard({
  src,
  link,
  h3,
  log,
  projectType,
  docLink,
  repoLink,
  onSelect,
}) {
  return (
    <div className="project-card" onClick={onSelect}>
      <img src={src} alt={h3} />
      <div className="text-container">
        <h3>{h3}</h3>
        <p>{log}</p>
        <p>{projectType}</p>
      </div>
      {/* <div className="button-container">
        <a href={link}>
          <button>View Project</button>
        </a>
        <a href={repoLink}>
          <button id="github">GitHub</button>
        </a>
        <a href={docLink}>
          <button>Documentation</button>
        </a>
      </div> */}
    </div>
  );
}

export default ProjectCard;
