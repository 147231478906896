export const projects = [
  {
    src: require("../assets/img/PortfolioThumbnail.png"),
    h3: "Portfolio Website",
    log: "Personal Portfolio Created with React",
    projectType: "React | JavaScript",
    link: "",
    docLink: "",
    repoLink: "https://github.com/JamesMcQueenDev/James-McQueen-Dev-Portfolio",
    description:
      "This is my personal portfolio website. The website was created using React and JavaScript. The website showcases my skills, projects and contact information.",
  },

  {
    src: require("../assets/img/GameEngineThumbnail.png"),
    h3: "Simple Monogame Engine",
    log: "A game engine made in Monogame",
    projectType: "C# | Monogame XNA",
    link: "",
    docLink: "",
    repoLink: "https://github.com/JamesMcQueenDev/Simple-Monogame-Engine",
    description:
      "A simple game engine made in Monogame XNA. This engine was made to help me learn more about game development and how game engines work. The engine is still in development and is not yet complete.",
  },
  {
    src: require("../assets/img/ImageProcessingThumbnail.jpg"),
    h3: "Image Processing Application",
    log: "An Image Processor",
    projectType: "C# | .NET",
    link: "",
    docLink: "",
    repoLink: "https://github.com/JamesMcQueenDev/Image-Processing-Application",
    description:
      "An image processing application that allows the user to apply various filters to an image. The application also allows the user to save the image to their computer.",
  },
  {
    src: require("../assets/img/NavigationApp.jpg"),

    h3: "Android Navigation Application",
    log: "Task Based Application",
    projectType: "Java | Android Studio",
    link: "",
    docLink: "",
    repoLink:
      "https://github.com/JamesMcQueenDev/Android-Navigation-Application",
    description:
      "This was project was part of an assignment during my second year of university. The assignment was to create an Android application which logged a task and provided the user a notification if the user is in proximity of the location of that task. This assignment gained me an A in the module.",
  },
];
