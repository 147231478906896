import React, { useState } from "react";
import { projects } from "../../lib/projects";
import ProjectCard from "../../components/ProjectCard/ProjectCard";
import ProjectModal from "../../components/ProjectCard/ProjectModal";
import "./ProjectsStyles.css";

function Projects() {
  const [selectedProject, setSelectedProject] = useState(null);

  const handleSelectProject = (project) => {
    setSelectedProject(project);
  };

  const handleCloseModal = () => {
    setSelectedProject(null);
  };

  return (
    <div>
      <h1 className="section-title">Projects</h1>

      <div className="projects-container">
        {projects.map((project, index) => (
          <ProjectCard
            key={index}
            src={project.src}
            h3={project.h3}
            log={project.log}
            projectType={project.projectType}
            docLink={project.docLink}
            repoLink={project.repoLink}
            onSelect={() => handleSelectProject(project)}
          />
        ))}
      </div>
      {selectedProject && (
        <ProjectModal project={selectedProject} onClose={handleCloseModal} />
      )}
    </div>
  );
}

export default Projects;
