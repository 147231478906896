import {
  faGithub,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import CV from "../../assets/pdfs/CV.pdf";
import user from "../../lib/user";
import "./HeroStyle.css";

function Hero() {
  const userData = user[0];

  return (
    <div className="hero-container">
      <div className="info">
        <h1>{userData.name}</h1>
        <h2>{userData.role}</h2>
        <span>
          <a href={userData.github}>
            <FontAwesomeIcon icon={faGithub} />
          </a>
          <a href={userData.linkedin}>
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a href={userData.youtube}>
            <FontAwesomeIcon icon={faYoutube} />
          </a>
        </span>
        <p className="description">I am a Developer from {userData.location}</p>
        <div className="cv-container">
          <a href={CV}>
            <button className="hover">CV</button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Hero;
