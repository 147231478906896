import React from "react";
import "./SkillsStyles.css";
import SkillList from "../../components/SkillList/SkillList";

function Skills() {
  return (
    <div className="container">
      <h1 className="section-title">Skills</h1>

      <div className="skill-list">
        <SkillList p="HTML" />
        <SkillList p="CSS" />
        <SkillList p="JavaScript" />
      </div>
      <div className="skill-list">
        <SkillList p="React" />
      </div>
    </div>
  );
}

export default Skills;
