import React from "react";
import Footer from "../../features/Footer/Footer";
import Hero from "../../features/Hero/Hero";
import Projects from "../../features/Projects/Projects";
import Skills from "../../features/Skills/Skills";

function Home() {
  return (
    <div>
      <Hero />
      <Projects />
      <Skills />
      <Footer />
    </div>
  );
}

export default Home;
