import React, { useEffect, useState } from "react";
import "./ProjectModal.css";

const ProjectModal = ({ project, onClose }) => {

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (project) {
      setIsOpen(true);
    } 
    else {
      setIsOpen(false);
    }
  }, [project]);

  if (!project) return null;

  return (
    <div className={`modal-overlay ${isOpen ? "open" : ""}`} onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <img src={project.src} alt={project.h3} />
        <h2 className="data">{project.h3}</h2>
        <p className="data italic bold">{project.log}</p>
        <p className="data">{project.projectType}</p>
        <div className="project-description data">
          <p>{project.description}</p>
        </div>
        <div className="button-container">
          {project.link && (
            <a href={project.link}>
              <button>View Project</button>
            </a>
          )}
          {project.repoLink && (
            <a href={project.repoLink}>
              <button id="github">GitHub</button>
            </a>
          )}
          {project.docLink && (
            <a href={project.docLink}>
              <button>Doc</button>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectModal;
